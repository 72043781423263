<template>
  <div class="audioRecorder_wrap theme_bg_red">
    <div class="audioRecorder_content">
      <div id="audioRecorderOperate">
        <div class="logo_handred_wrap flex-re">
          <div class="logo_handred"></div>
        </div>
        <div class="recorder_card">
          <div class="title_wrap">
            <div class="info_title flex-cc">
              <div class="theme_logo"></div>
              <div class="title_main">
                <h4 class="fs19">共产党宣言</h4>
                <h5 class="fs14">
                  {{ createdTimer }}
                </h5>
              </div>
            </div>
          </div>
          <div class="info_wrap theme_bg_white">
            <div class="title_main tc">
              <h5 class="fs16">宣读者：{{ detailData.xuanyan_user_name }}</h5>
            </div>
            <div class="info_essay fs16">
              {{ detailData.paragraph.xuanyan }}
            </div>
            <div class="flex-cc">
              <div class="recorder_btn">
                <div class="recorder_bg_btn flex-cc theme_white fs18 fw7" @click="handleRecorder">
                  <!-- <span v-if="isFirst">
                    开始
                    <br />
                    播放
                  </span>
                  <span v-else>
                    <span v-if="isRecorder">
                      正在
                      <br />
                      播放
                    </span>
                    <span v-else>
                      继续
                      <br />
                      播放
                    </span>
                  </span> -->
                  <span v-if="isFirst" class="detailPlay"></span>
                  <span v-else>
                    <span v-if="isRecorder" class="play_ing"></span>
                    <span v-else class="detailPlay"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form_card theme_bg_white" v-if="!!detailData.comment">
          <div class="form_info">
            <span class="theme_red fs15 label_info">宣读感言:</span>
            <van-field v-model="detailData.comment" rows="1" autosize label="" type="textarea" placeholder="" :readonly="true" />
          </div>
        </div>
        <div class="upload_operate">
          <div style="position: relative;">
            <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
              <script type="text/wxtag-template">
                <style>.btn{
                        width: 100%;
                        height: 45px;
                        background: transparent;
                      }</style>
                  <div class="btn"></div>
              </script>
            </wx-open-launch-app>
            <div class="default_button_yellow flex-cc">邀请给朋友接力</div>
          </div>
          <div style="position: relative;">
            <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
              <script type="text/wxtag-template">
                <style>.btn{
                        width: 100%;
                        height: 45px;
                        background: transparent;
                      }</style>
                  <div class="btn"></div>
              </script>
            </wx-open-launch-app>
            <div class="default_button_normal flex-cc">我也要接力</div>
          </div>
        </div>

        <audioPlay ref="BGMAudioPlay" @overPlay="overPlay" :currentBGM="currentBGM" />
      </div>
    </div>
    <OpenApp2 :path="path" :id="id" :url="url" />
  </div>
</template>

<script>
  /**分享 */
  import OpenApp2 from "@/components/openApp2";
  import linkApp from "@/utils/linkApp";
  import { Toast } from "vant";
  import comComp from "./common/index";
  import { getAudiosDetail } from "./hooks/useApi";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  import { getIndexDate } from "./api";
  import { isWeixin } from "@/utils/common";
  export default {
    name: "sharePart",

    components: {
      ...comComp,
      OpenApp2,
    },

    data() {
      return {
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
        detailData: {
          xuanyan_user_name: "",
          paragraph: {
            xuanyan: "",
          },
          comment: "",
          status: "",
          created_at_timestamp: "",
        },

        isFirst: true,
        isRecorder: true,
        currentBGM: "",

        path: "openWebview",
        url: "index.html/index/entry",
        id: "index.html/index/entry",
      };
    },

    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          url: this.url,
          id: this.id,
        });
      },
      createdTimer: {
        get() {
          const timestamp = this.detailData.created_at_timestamp;
          if (!timestamp) return "";
          return this.formatDate(timestamp);
        },
      },
    },
    mounted() {
      this.fetchDataDetail();
      this.getIndexDate();
    },

    methods: {
      openError() {
        this.$router.push({ name: "index" });
      },
      async getIndexDate() {
        const res = await getIndexDate();
        wxShare(res.data.data.share_config.title, res.data.data.share_config.share_img, window.location.href, res.data.data.share_config.summary);
      },
      async fetchDataDetail() {
        const { id } = this.$route.query;
        let res = await getAudiosDetail(id);
        console.log(res.data, "getAudiosDetail");
        this.detailData = res.data.data;
        const { audio_path } = res.data.data;
        this.currentBGM = {
          isPlay: false,
          url: audio_path,
          loop: false,
          isChecked: true,
        };
      },

      handleRecorder() {
        if (this.isFirst) {
          this.$refs["BGMAudioPlay"].resumePlay();
          this.isFirst = false;
        } else {
          this.isRecorder = !this.isRecorder;
          if (this.isRecorder) {
            this.$refs["BGMAudioPlay"].resumePlay();
          } else {
            this.$refs["BGMAudioPlay"].pausePlay();
          }
        }
      },

      overPlay() {
        console.log("播放完成");
        this.isFirst = true;
        this.isRecorder = true;
      },

      formatDate(date) {
        if (date.toString().length == 10) {
          date = date * 1000;
        }
        date = new Date(date);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        m = m < 10 ? "0" + m : m;
        d = d < 10 ? "0" + d : d;
        return y + "." + m + "." + d;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "./static/css/index.scss";
</style>
